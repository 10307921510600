<template>
  <div>
    <div class="row col-md-12" v-if="years">
      <select
        class="form-control form-control-sm shadow-none col-md-2"
        v-model="filter.status"
        @change="changeFilter"
      >
        <option value="all">все отчеты</option>
        <option value="create">созданные</option>
        <option value="check">на проверке</option>
        <option value="confirm" class="confitm">подтвержденные</option>
      </select>
      <span class="p">за</span>
      <select
        class="form-control form-control-sm shadow-none col-md-2"
        v-model="filter_year"
        @change="changeFilter"
      >
        <option
          v-for="(item, idx) in years"
          :key="idx"
          :value="years.length > 1 ? item : 'all'"
          >{{ item }} год</option
        >
        <option value="all" v-if="years.length > 1">{{
          years[0] + " - " + years[years.length - 1] + " года"
        }}</option>
      </select>
      <input
        v-if="USER_INFO.status === 'admin'"
        type="text"
        class="form-control form-control-sm input-form shadow-none col-md-3"
        v-model="inputSearch"
        @input="filterReport"
        placeholder="поиск по названию сц"
      />
    </div>
    <div
      class="row col-md-12"
      v-if="
        filter.year !== 'all' && USER_INFO.status === 'admin' && reportsList
      "
    >
      <select
        class="form-control form-control-sm shadow-none col-md-2"
        v-model="REPORTSFILTER.period_start"
        @change="changeFilter"
      >
        <option
          v-for="(item, idx) in mounts"
          :key="idx + 'mounts'"
          :value="item.value"
          >{{ item.name }}</option
        >
      </select>
      <span class="p">-</span>
      <select
        class="form-control form-control-sm shadow-none col-md-2"
        v-model="REPORTSFILTER.period_end"
        @change="changeFilter"
      >
        <option
          v-for="(item, idx) in mounts"
          :key="idx + 'mounts'"
          :value="item.value"
          >{{ item.name }}</option
        >
      </select>
    </div>
    <div class="table-wrapper">
      <table class="table table-sm" v-if="reportsList">
        <tr>
          <th></th>
          <th v-if="USER_INFO.status === 'admin'">СЦ</th>
          <th>начало</th>
          <th>конец</th>
          <th>запчасти</th>
          <th>работы</th>
          <th>сумма</th>
          <th>дата создания</th>
          <th>на проверке</th>
          <th>подтвержден</th>
        </tr>
        <tr>
          <th></th>
          <th v-if="USER_INFO.status === 'admin'"></th>
          <th></th>
          <th></th>
          <th class="itog">{{ number(summ_zap) }}</th>
          <th class="itog">{{ number(summ_rabot) }}</th>
          <th class="itog">{{ number(summ) }}</th>
          <th></th>
          <th></th>
          <th></th>
        </tr>
        <tr
          v-for="(item, idx) in reportsList"
          :key="idx"
          :class="
            !item.date_report_confirm
              ? item.date_report_check
                ? `cursor bg-warning`
                : 'cursor'
              : 'cursor bg-success text-white'
          "
          @click="goReport(item.id)"
        >
          <td>{{ idx + 1 }}</td>
          <td v-if="USER_INFO.status === 'admin'">
            <div>{{ item.user }}</div>
            <div class="text-danger font-weight-bold" v-if="item.zap_free">
              есть бесплатные зч
            </div>
          </td>
          <td>{{ item.date_start }}</td>
          <td>{{ item.date_end }}</td>
          <td class="text-nowrap">{{ number(item.summa_zap) }}</td>
          <td class="text-nowrap">{{ number(item.summa_rabot) }}</td>
          <td class="text-nowrap">{{ number(item.summa) }}</td>
          <td>{{ item.date_add }}</td>
          <td>{{ item.date_report_check ? item.date_report_check : "нет" }}</td>
          <td>{{ item.report_confirm ? item.date_report_confirm : "нет" }}</td>
        </tr>
      </table>
    </div>
    <Loader v-if="loading" />
  </div>
</template>
<script>
import { HTTPGET } from "@/api";
import { number_format } from "@/components/functions/number_format.js";
import { mapGetters, mapMutations } from "vuex";
import Loader from "@/components/Loader.vue";
export default {
  name: "ReportsList",
  components: {
    Loader
  },
  computed: {
    ...mapGetters([
      "USER_INFO",
      "YEAR_FOR_REPORTS_LIST_FILTER",
      "REPORTSFILTER"
    ]),
    summ_zap: function() {
      let summ = 0;
      this.reportsList.map(item => {
        summ += Number(item.summa_zap);
      });
      return summ;
    },
    summ_rabot: function() {
      let summ = 0;
      this.reportsList.map(item => {
        summ += Number(item.summa_rabot);
      });
      return summ;
    },
    summ: function() {
      let summ = 0;
      this.reportsList.map(item => {
        summ += Number(item.summa);
      });
      return summ;
    },
    filter_year: {
      get() {
        return this.YEAR_FOR_REPORTS_LIST_FILTER;
      },
      set(value) {
        this.set_year_for_reports_list_filter(value);
      }
    }
  },
  data: () => ({
    reportsList: null,
    years: null,
    filterList: null,
    inputSearch: "",
    filter: {
      year: new Date().getFullYear(),
      status: "all"
    },
    mounts: [
      { name: "январь", value: "01" },
      { name: "февраль", value: "02" },
      { name: "март", value: "03" },
      { name: "апрель", value: "04" },
      { name: "май", value: "05" },
      { name: "июнь", value: "06" },
      { name: "июль", value: "07" },
      { name: "август", value: "08" },
      { name: "сентябрь", value: "09" },
      { name: "октябрь", value: "10" },
      { name: "ноябрь", value: "11" },
      { name: "декабрь", value: "12" }
    ],
    loading: true
  }),
  methods: {
    ...mapMutations([
      "set_year_for_reports_list_filter",
      "set_period_start",
      "set_period_end"
    ]),
    get_data() {
      this.loading = true;
      this.filter.year = this.YEAR_FOR_REPORTS_LIST_FILTER;
      if (this.filter.year === "all") {
        this.set_period_start("01");
        this.set_period_end("12");
      }
      HTTPGET({
        url: "/reports/get_reports_list.php",
        params: {
          filter: this.filter,
          period_start: this.REPORTSFILTER.period_start,
          period_end: this.REPORTSFILTER.period_end
        }
      }).then(response => {
        this.reportsList = response.data.reports;
        this.years = response.data.years;
        this.filterList = response.data.reports;
        this.loading = false;
      });
    },
    number(s) {
      return number_format(s, 2, ",", " ");
    },
    goReport(id) {
      //this.$router.push(`/reports/report/${id}`);
      window.open(`/reports/report/${id}`, "_blank");
    },
    changeFilter() {
      this.get_data();
    },
    filterReport() {
      this.reportsList = this.filterList.filter(item =>
        item.user.toLowerCase().includes(this.inputSearch.toLowerCase())
      );
    }
  },
  mounted() {
    this.get_data();
  }
};
</script>
<style lang="scss" scoped>
table {
  font-size: 0.8em;
}
.table-wrapper {
  overflow: auto;
}
.cursor {
  cursor: pointer;
}
.p {
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid #ced4da;
  width: 3em;
  text-align: center;
}
input,
select,
span {
  margin-top: 5px;
  margin-bottom: 5px;
}
.itog {
  font-size: 0.7em;
}
</style>
